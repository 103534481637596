<template>
    <v-form ref="form" lazy-validation>
        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg" color="cyan accent-4" flat>
                    <v-container>
                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <div
                                    class="font-weight-bold text-h6 white--text"
                                >
                                    Find your flight
                                </div>
                            </v-col>

                            <v-col cols="12">
                                <v-radio-group
                                    v-model="toggle"
                                    @change="changedSearchType()"
                                    class="ma-0"
                                    dark
                                    dense
                                >
                                    <v-radio
                                        color="grey darken-3"
                                        value="roundTrip"
                                    >
                                        <template v-slot:label>
                                            <strong
                                                class="grey--text text--lighten-5"
                                            >
                                                Round Trip
                                            </strong>
                                        </template>
                                    </v-radio>

                                    <v-radio
                                        color="grey darken-3"
                                        value="oneWay"
                                    >
                                        <template v-slot:label>
                                            <strong
                                                class="grey--text text--lighten-5"
                                            >
                                                One Way
                                            </strong>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-col>

                            <v-col class="d-flex pb-1" cols="12">
                                <v-card color="white" flat rounded width="100%">
                                    <v-row>
                                        <v-col cols="12">
                                            <AirportSearch
                                                :filled="true"
                                                :value="getSearchFrom"
                                                color="white"
                                                text="From"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <v-col class="d-flex pb-1" cols="12">
                                <v-card color="white" flat rounded width="100%">
                                    <v-row>
                                        <v-col cols="12">
                                            <AirportSearch
                                                :filled="true"
                                                :value="getSearchTo"
                                                color="white"
                                                text="To"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <v-col class="d-flex pb-1" cols="12">
                                <v-card color="white" flat rounded width="100%">
                                    <v-row>
                                        <v-col cols="12">
                                            <FlightDates />
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <v-col class="d-flex pb-1" cols="12">
                                <v-card color="white" flat rounded width="100%">
                                    <v-row>
                                        <v-col cols="12">
                                            <Passengers />
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <v-col class="pb-6" cols="12">
                                <v-btn
                                    @click="saveForm()"
                                    block
                                    class="font-weight-bold px-12 rounded"
                                    color="deep-purple darken-4"
                                    dark
                                    elevation="0"
                                    height="56"
                                    x-large
                                >
                                    Search
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-0" cols="12">
                <v-card color="transparent" flat width="100%">
                    <v-container>
                        <v-row
                            class="align-baseline d-flex justify-space-between"
                        >
                            <v-col class="text-h6" cols="6"> Filters </v-col>

                            <v-col class="text-h6 text-right" cols="6">
                                <a
                                    href="/search/depart"
                                    class="font-weight-bold cyan--text text-body-2"
                                >
                                    Clear filters
                                </a>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <v-col class="d-flex flex-column pt-0" cols="12">
                <v-expansion-panels v-model="panel" flat multiple>
                    <v-divider class="my-3 px-3" />

                    <v-expansion-panel>
                        <v-expansion-panel-header
                            class="font-weight-bold"
                            color="grey lighten-4"
                        >
                            Price
                        </v-expansion-panel-header>

                        <v-expansion-panel-content
                            class="font-weight-bold"
                            color="grey lighten-4"
                        >
                            <div class="d-flex flex-wrap py-0">
                                <v-range-slider
                                    v-on:end="updateMaxAndMinPrice"
                                    v-model="range"
                                    :max="getMaxPrice"
                                    :min="getMinPrice"
                                    style="width: 100%"
                                    track-color="rgba(96, 171, 192, .4)"
                                />

                                <v-row class="d-flex justify-space-between">
                                    <v-col class="text-body-2" cols="6">
                                        {{ getCurrency }} {{ range[0] }}
                                    </v-col>

                                    <v-col
                                        class="text-body-2 text-right"
                                        cols="6"
                                    >
                                        {{ getCurrency }} {{ range[1] }}
                                    </v-col>
                                </v-row>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-divider class="my-3 px-3" />

                    <v-expansion-panel
                        v-if="$router.currentRoute.path === '/search/depart'"
                    >
                        <v-expansion-panel-header
                            class="font-weight-bold"
                            color="grey lighten-4"
                        >
                            Aircraft Type
                        </v-expansion-panel-header>

                        <v-expansion-panel-content
                            class="font-weight-bold"
                            color="grey lighten-4"
                        >
                            <v-radio-group
                                v-model="departureToggle"
                                @change="
                                    toggleSearchParams(
                                        departureToggle,
                                        'departureClass'
                                    )
                                "
                                class="ma-0"
                                dense
                            >
                                <v-radio value="ALL" class="mb-3" color="cyan">
                                    <template v-slot:label> All </template>
                                </v-radio>

                                <v-radio value="AICRAFT" color="cyan">
                                    <template v-slot:label> Airplane </template>
                                </v-radio>

                                <v-radio value="HELICOPTER" color="cyan">
                                    <template v-slot:label>
                                        Helicopter
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-divider class="my-3 px-3" />

                    <v-expansion-panel
                        v-if="$router.currentRoute.path === '/search/return'"
                    >
                        <v-expansion-panel-header
                            class="font-weight-bold"
                            color="grey lighten-4"
                        >
                            Aircraft Type
                        </v-expansion-panel-header>

                        <v-expansion-panel-content
                            class="font-weight-bold"
                            color="grey lighten-4"
                        >
                            <v-radio-group
                                v-model="arrivalToggle"
                                @change="
                                    toggleSearchParams(
                                        arrivalToggle,
                                        'arrivalClass'
                                    )
                                "
                                class="ma-0"
                                dense
                            >
                                <v-radio value="ALL" class="mb-3" color="cyan">
                                    <template v-slot:label> All </template>
                                </v-radio>

                                <v-radio value="AICRAFT" color="cyan">
                                    <template v-slot:label> Airplane </template>
                                </v-radio>

                                <v-radio value="HELICOPTER" color="cyan">
                                    <template v-slot:label>
                                        Helicopter
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-divider class="my-3 px-3" />

                    <v-expansion-panel>
                        <v-expansion-panel-header
                            class="font-weight-bold"
                            color="grey lighten-4"
                        >
                            Stops
                        </v-expansion-panel-header>

                        <v-expansion-panel-content
                            class="font-weight-bold"
                            color="grey lighten-4"
                        >
                            <v-radio-group
                                @change="
                                    toggleSearchParams(scalesToggle, 'scales')
                                "
                                v-model="scalesToggle"
                                class="ma-0"
                                dense
                            >
                                <v-radio :value="3">
                                    <template v-slot:label> All </template>
                                </v-radio>

                                <v-radio :value="0">
                                    <template v-slot:label> Nonstop </template>
                                </v-radio>

                                <v-radio :value="1">
                                    <template v-slot:label>
                                        Max 1 stop
                                    </template>
                                </v-radio>

                                <v-radio :value="2">
                                    <template v-slot:label>
                                        Max 2 stop or more</template
                                    >
                                </v-radio>
                            </v-radio-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-divider class="my-3 px-3" />

                    <v-expansion-panel>
                        <v-expansion-panel-header
                            class="font-weight-bold"
                            color="grey lighten-4"
                        >
                            Order By
                        </v-expansion-panel-header>

                        <v-expansion-panel-content
                            class="font-weight-bold"
                            color="grey lighten-4"
                        >
                            <v-radio-group
                                @change="
                                    toggleSearchParams(orderByToggle, 'orderBy')
                                "
                                v-model="orderByToggle"
                                class="ma-0"
                                dense
                            >
                                <v-radio
                                    value="PRICE"
                                    class="mb-3"
                                    color="cyan"
                                >
                                    <template v-slot:label> Price </template>
                                </v-radio>

                                <v-radio value="DURATION" color="cyan">
                                    <template v-slot:label> Duration </template>
                                </v-radio>
                            </v-radio-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AirportSearch from "./AirportSearch";
import FlightDates from "../../components/search/FlightDates";
import Passengers from "./Passengers";

export default {
    components: {
        AirportSearch,
        FlightDates,
        Passengers,
    },
    data: () => ({
        panel: [0],
        adultsList: ["0", "1", "2", "3", "4", "5+"],
        kidsList: ["0", "1", "2", "3", "4", "5+"],
        categories: ["All", "Airplane", "Helicopter"],
        modalDate1: false,
        modalDate2: false,
        dateFrom: "",
        dateTo: "",
        adults: "",
        kids: "",
        range: [],
        departureToggle: "ALL",
        arrivalToggle: "ALL",
        scalesToggle: 3,
        orderByToggle: "PRICE",
    }),
    computed: {
        ...mapGetters([
            "getFlightFrom",
            "getFlightTo",
            "getSearchFrom",
            "getSearchTo",
            "getFromDate",
            "getToDate",
            "getCategory",
            "getTypeSearch",
            "getMinPrice",
            "getMaxPrice",
            "getCurrency",
        ]),
    },
    methods: {
        ...mapActions([
            "updateFilter",
            "actionFromSearch",
            "actionToSearch",
            "fromDateAction",
            "loadSearchResult",
            "toDateAction",
            "typeSearchAction",
            "flightToAction",
            "actionFromType",
            "actionToType",
        ]),
        toggleSearchParams(Model, value) {
            this.updateFilter({
                data: Model,
                value,
                refresh: false,
            });
            this.updateResults();
        },
        updateMaxAndMinPrice(event) {
            this.updateFilter({
                data: event[0],
                value: "minPrice",
                refresh: false,
            });
            this.updateFilter({
                data: event[1],
                value: "maxPrice",
                refresh: false,
            });
            this.updateResults();
        },
        updateResults() {
            this.$store.dispatch("loadSearchResult", null, { root: true });
        },
        formatDateInput(Dates) {
            let format = "ddd, DD MMM, YYYY HH:mm";
            return (
                Dates.start.format(format) + " - " + Dates.end.format(format)
            );
        },
        changedSearchType() {
            this.typeSearchAction(this.toggle);
        },
        isRoundTrip() {
            return this.getTypeSearch === "roundTrip";
        },
        saveForm() {
          if (!(!this.$refs.form.validate() && this.validate())) {
            this.typeSearchAction(this.toggle);
            this.actionFromSearch(this.getSearchFrom);
            this.actionToSearch(this.getSearchTo);
            this.flightToAction({});
            this.actionFromType(null);
            this.actionToType(null);
            this.loadSearchResult();
            if (this.$router.currentRoute.path !== "/search/depart") {
              this.$router.push("/search/depart");
            }
          }
        },

        validate() {
            this.rules.from.value = this.getFlightFrom;
            this.rules.to.value = this.getFlightTo;

            for (const prop in this.rules) {
                let elem = this.rules[prop];
                if (elem.value) {
                    if (elem.value.length > elem.minLenght) {
                        elem.pass = true;
                    } else {
                        elem.pass = false;
                        this.error = true;
                    }
                } else {
                    elem.pass = false;
                    this.error = true;
                }
            }

            return this.error;
        },
    },
    created() {
        this.toggle = this.getTypeSearch;
        setTimeout(() => {
            this.range = [this.getMinPrice, this.getMaxPrice];
        }, 1000);
    },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
    margin-top: 0 !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 0 !important;
}
</style>

<style lang="scss">
.v-slider--horizontal {
    margin-right: 0;

    .v-slider__track-container {
        border-radius: 10px;
        height: 6px !important;
        overflow: hidden;
    }
}
</style>
