import { flightGds, flightsApi, flightsCommercialsApi } from '@/services/axiosURI.service';
import * as dayjs from 'dayjs';

const state = {
	searchResultFlightsDeals: [],
	searchResultFlightsGDS: [],
	searchResultFlightsGDSOneWay: [],
	searchResultFlightsCommercials: [],
	currency: '',
};

// actions
const actions = {
	async loadSearchResult({ commit, rootState }, data) {
		let paramsQuery = rootState.filters.searchParams;
		paramsQuery.sourceId = rootState.airports.searchFrom.id;
		paramsQuery.destinationId = rootState.airports.searchTo.id;
		paramsQuery.pax =
			parseInt(rootState.filters.adults) +
			parseInt(rootState.filters.children) +
			parseInt(rootState.filters.lapInfants);
		paramsQuery.oneWay = rootState.filters.typeSearch === 'oneWay';
		paramsQuery.dateDepart = rootState.filters.fromDate;
		paramsQuery.dateReturn = rootState.filters.toDate;
		paramsQuery.timeDepart = dayjs(rootState.filters.fromDate).format('HH:mm');
		paramsQuery.timeReturn = dayjs(rootState.filters.toDate).format('HH:mm');

		const {
			sourceId,
			destinationId,
			limit,
			pax,
			oneWay,
			dateDepart,
			timeDepart,
			scales,
			dateReturn,
			timeReturn,
			departureClass,
			arrivalClass,
			orderBy,
			minPrice,
			maxPrice,
		} = paramsQuery;

		const searchFlightsDealsPromise = flightsApi.getFlights(
			sourceId,
			destinationId,
			limit,
			pax,
			oneWay,
			dateDepart,
			scales,
			dateReturn,
			departureClass,
			arrivalClass,
			orderBy,
			minPrice,
			maxPrice
		).then((response) => {
			const {data} = response.data;
			setMaxAndMinPrice({commit, rootState});
			commit('setSearchResultFlightsDeals', data);
		}).catch((error) => {
			console.error(error);
			commit('setSearchResultFlightsDeals', []);
		});

		let searchFlightsGDSPromise;
		if (rootState.filters.typeSearch !== 'roundTrip') {
			commit('setSearchResultFlightsGDS', []);
			searchFlightsGDSPromise = Promise.resolve();
		} else {
			searchFlightsGDSPromise = flightGds.getFlightsGDS(
				sourceId,
				destinationId,
				limit,
				pax,
				oneWay,
				dateDepart,
				timeDepart,
				timeReturn,
				scales,
				departureClass,
				dateReturn,
				arrivalClass,
				orderBy,
				minPrice,
				maxPrice
			).then((response) => {
				const {data} = response.data;
				setMaxAndMinPrice({commit, rootState});
				commit('setSearchResultFlightsGDS', data);
			}).catch((error) => {
				console.error(error);
				commit('setSearchResultFlightsGDS', []);
			});
		}

		const searchFlightsGDSOneWayPromise = flightGds.getFlightsGDS(
			sourceId,
			destinationId,
			limit,
			pax,
			true,
			dateDepart,
			timeDepart,
			timeReturn,
			scales,
			departureClass,
			dateReturn,
			arrivalClass,
			orderBy,
			minPrice,
			maxPrice
		).then((response) => {
			const {data} = response.data;
			setMaxAndMinPrice({commit, rootState});
			commit('setSearchResultFlightsGDSOneWay', data);
		}).catch((error) => {
			console.error(error);
			commit('setSearchResultFlightsGDSOneWay', []);
		});

		const searchFlightsCommercialsPromise = flightsCommercialsApi.getFlightsCommercials(
			sourceId,
			destinationId,
			limit,
			pax,
			true,
			dateDepart,
			timeDepart,
			timeReturn,
			scales,
			departureClass,
			dateReturn,
			arrivalClass,
			orderBy,
			minPrice,
			maxPrice
		).then((response) => {
			const {data} = response.data;
			setMaxAndMinPrice({commit, rootState});
			commit('setSearchResultFlightsCommercials', data);
		}).catch((error) => {
			console.error(error);
			commit('setSearchResultFlightsCommercials', []);
		});

		await Promise.all([
			searchFlightsDealsPromise,
			searchFlightsGDSPromise,
			searchFlightsGDSOneWayPromise,
			searchFlightsCommercialsPromise,
		]);
	},

	async loadSearchResultInverted({ commit, rootState }, data) {
		let paramsQuery = rootState.filters.searchParams;
		paramsQuery.sourceId = rootState.airports.searchTo.id;
		paramsQuery.destinationId = rootState.airports.searchFrom.id;
		paramsQuery.pax =
			parseInt(rootState.filters.adults) +
			parseInt(rootState.filters.children) +
			parseInt(rootState.filters.lapInfants);
		paramsQuery.oneWay = true;
		paramsQuery.dateDepart = rootState.filters.toDate;
		paramsQuery.dateReturn = rootState.filters.toDate;
		paramsQuery.timeDepart = dayjs(rootState.filters.fromDate).format('HH:mm');
		paramsQuery.timeReturn = dayjs(rootState.filters.toDate).format('HH:mm');

		const {
			sourceId,
			destinationId,
			limit,
			pax,
			oneWay,
			dateDepart,
			timeDepart,
			scales,
			dateReturn,
			timeReturn,
			departureClass,
			arrivalClass,
			orderBy,
			minPrice,
			maxPrice,
		} = paramsQuery;

		await flightGds.getFlightsGDS(
			sourceId,
			destinationId,
			limit,
			pax,
			oneWay,
			dateDepart,
			timeDepart,
			timeReturn,
			scales,
			departureClass,
			dateReturn,
			arrivalClass,
			orderBy,
			minPrice,
			maxPrice
		).then((response) => {
			const {data} = response.data;
			setMaxAndMinPrice({commit, rootState});
			commit('setSearchResultFlightsGDSOneWay', data);
		}).catch((error) => {
			console.error(error);
			commit('setSearchResultFlightsGDSOneWay', []);
		});
	},
};

// mutations
const mutations = {
	resetRange(state) {
		state.searchResultFlightsDeals = searchResultSeats;
	},

	setSearchResultFlightsDeals(state, searchResultFlightsDeals) {
		state.searchResultFlightsDeals = searchResultFlightsDeals;
	},

	setSearchResultFlightsGDSOneWay(state, searchResultFlightsGDSOneWay) {
		state.searchResultFlightsGDSOneWay = searchResultFlightsGDSOneWay;
	},

	setSearchResultFlightsGDS(state, searchResultFlightsGDSPlane) {
		state.searchResultFlightsGDS = searchResultFlightsGDSPlane;
	},

	setSearchResultFlightsCommercials(state, searchResultFlightsCommercials) {
		state.searchResultFlightsCommercials = searchResultFlightsCommercials;
	},

	setCurrency(state, data) {
		state.currency = data;
	},
};

function setMaxAndMinPrice({ commit, rootState }) {
	setTimeout(() => {
		const allTrips = state.searchResultFlightsGDS.trips.concat(
			state.searchResultFlightsGDSOneWay.trips
		);
		allTrips.forEach((element) => {
			if (element.price > rootState.filters.searchParams.maxPrice) {
				commit('setMaxPrice', element.price, { root: true });
				commit('setCurrency', element.currency);
			}

			if (element.price < rootState.filters.searchParams.minPrice) {
				commit('setMinPrice', element.price, { root: true });
			}
		});
	}, 2000);
}

// getters
const getters = {
	getSearchResultSeats: (state) => state.searchResultFlightsDeals,
	getSearchResultPlane: (state) => state.searchResultFlightsGDS,
	getSearchResultPlaneOneWay: (state) => state.searchResultFlightsGDSOneWay,
	getSearchResultFlightsCommercials: (state) => state.searchResultFlightsCommercials,
	getCurrency: (state) => state.currency,
};
export default {
	state,
	getters,
	actions,
	mutations,
};
