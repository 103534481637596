<template>
	<v-container class="mb-15 pt-12 pb-9">
		<v-row class="justify-space-between">
			<v-col class="d-none d-md-block" cols="12" md="3">
				<Aside />
			</v-col>

			<v-col cols="12" md="9">
				<v-row>
					<v-col cols="12" class="font-weight-bold text-h6">
						Your selected depart flight:
					</v-col>

					<v-col v-if="getFromType === 'Offer'" cols="12">
						<v-expansion-panels flat>
							<v-expansion-panel>
								<v-expansion-panel-header>
									<v-row class="my-n4">
										<v-col class="pa-0" cols="12">
											<FlightDetails
												v-if="getFromType === 'Offer'"
												:flight="getFlightFrom"
											/>

											<FlightDetailsGDS
												v-else
												:flight="getFlightFrom"
												:flightData="getFlightFrom.oneWay"
											/>
										</v-col>
									</v-row>
								</v-expansion-panel-header>

								<v-expansion-panel-content v-if="getFlightFrom.services">
									<v-col cols="12">
										<FlightFeatures :flight="getFlightFrom" />
									</v-col>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-col>

					<v-col v-else>
						<v-expansion-panels flat>
							<v-expansion-panel>
								<v-expansion-panel-header>
									<v-row class="my-n4">
										<v-col class="pa-0" cols="12">
											<FlightDetails
												v-if="getFromType === 'Offer'"
												:flight="getFlightFrom"
											/>

											<FlightDetailsGDS
												v-else
												:flight="getFlightFrom"
												:flightData="getFlightFrom.oneWay"
											/>
										</v-col>
									</v-row>
								</v-expansion-panel-header>

								<v-expansion-panel-content  v-if="getFlightFrom.services">
									<v-col cols="12">
										<FlightFeatures :flight="getFlightFrom" />
									</v-col>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-col>

					<v-col class="pt-6" cols="12">
						<v-divider />
					</v-col>

					<v-col class="pt-6" cols="12">
						<div class="font-weight-bold text-h6">
							Select your
							<span class="cyan--text">return</span>
							flight to
							<span class="cyan--text"
								>{{ getSearchFrom.city }}, {{ getSearchFrom.state }}</span
							>
						</div>

						<p class="text-body-1">
							Prices are one way per person, all taxes and fees included, do not
							include baggage fees.
						</p>
					</v-col>

					<v-col
						v-if="getSearchResultSeats.returnTrips.length"
						class="font-weight-bold text-h6"
						cols="12"
					>
						Search by seats
					</v-col>

					<v-col
						v-for="(item, i) in getSearchResultSeats.returnTrips"
						:key="i"
						cols="12"
					>
						<v-hover v-slot="{ hover }">
							<v-card
								@click="saveReturn(item)"
								:elevation="hover ? 6 : 0"
								class="overflow-hidden pa-6 rounded-lg transition-swing"
								flat
								width="100%"
							>
								<v-row>
									<v-col class="d-flex flex-column flex-wrap" cols="12" md="8">
										<v-row>
											<v-col class="align-start d-flex" cols="12">
												<v-img
													:src="getCompanyLogo(item)"
													class="mt-1 mr-6 rounded"
													contain
													height="38px"
													max-width="100px"
												/>

												<div class="d-flex flex-column">
													<span
														v-text="getCompanyName(item)"
														class="font-weight-bold text-body-1"
													/>

													<div class="font-weight-bold text-body-2">
														<span v-text="getBrand(item)" />

														<span v-text="' - '" />

														<span v-text="getModel(item)" />
													</div>
												</div>
											</v-col>

											<v-col class="trip__grid font-weight-bold text-body-2">
												<div class="trip__grid--where-from text-center">
													{{ item.source.city }} (JFK)
												</div>

												<div class="trip__grid--time-from text-center">
													{{ getHour(item.flightDate) }}
													-
													{{ getDateFormat(item.flightDate) }}
												</div>

												<div
													v-if="item.scales == 0"
													class="trip__grid--type green--text text--lighten-1 text-center"
												>
													Nonstop
												</div>
												<div
													v-if="item.scales == 1"
													class="trip__grid--type green--text text--lighten-1 text-center"
												>
													1 Scale
												</div>
												<div
													v-if="item.scales > 1"
													class="trip__grid--type green--text text--lighten-1 text-center"
												>
													{{ item.scales }} Scales
												</div>

												<div class="trip__grid--where-to text-center">
													{{ item.destination.city }}
													({{ item.destination.code }})
												</div>

												<div class="trip__grid--time-to text-center">
													{{ getHour(item.landingTime) }}
													-
													{{ getDateFormat(item.flightDate) }}
												</div>
											</v-col>

											<v-col
												class="align-center d-flex flex-column flex-md-row justify-space-between font-weight-bold text-body-2"
												cols="12"
											>
												<div>
													Duration:
													{{ parseDuration(item.flightTime) }}
												</div>

												<div class="cyan--text font-weight-bold text-body-1">
													{{ getSegment(item) }}
												</div>
											</v-col>

											<v-col class="font-weight-bold text-body-2" cols="12">
												<v-card
													class="d-flex align-center flex-row justify-space-between pa-3 rounded-lg"
													color="green lighten-5"
													flat
													width="100%"
												>
													<div class="mr-3 green--text text--darken-3">
														Greenhouse gas emissions for this flight are
														compensated by V1
													</div>

													<v-spacer />

													<v-img
														contain
														height="44px"
														max-width="44px"
														src="@/assets/badge__v1--green.png"
														style="border-radius: 50%"
														width="44px"
													/>

													<v-spacer />
												</v-card>
											</v-col>
										</v-row>
									</v-col>

									<v-col
										class="d-none d-md-flex justify-center my-n3 py-0"
										cols="1"
									>
										<v-divider vertical style="border-style: dashed" />
									</v-col>

									<v-col
										class="d-flex d-md-none mx-n3 px-0"
										cols="12"
										style="flex: auto; max-width: calc(100% + 24px)"
									>
										<v-divider style="border-style: dashed" />
									</v-col>

									<v-col
										class="d-flex flex-column justify-center justify-md-space-between text-center text-md-right"
										cols="12"
										md="3"
									>
										<div
											class="align-center d-flex justify-center justify-md-end font-weight-bold text-h6"
										>
											<v-icon color="cyan"> mdi-seat-passenger </v-icon>

											{{ item.currency }} {{ item.price }}
										</div>

										<div class="mt-1 text-body-1">Price per seat</div>

										<div
											class="align-center d-flex font-weight-bold justify-center justify-md-end mt-1 cyan--text text-body-2"
										>
											<v-icon color="cyan" small> mdi-seat-passenger </v-icon>
                                            {{ getPax(item) - getPaxAvailable(item) }}/{{
                                                getPaxAvailable(item)
                                            }}
										</div>

										<div
											class="align-center d-flex font-weight-bold justify-center justify-md-end mt-1 mb-6 mb-md-auto red--text text-body-2"
										>
											Only
											{{ getPaxAvailable(item) }} seats left!
										</div>

										<div>
											<v-btn
												@click="saveReturn(item)"
												class="font-weight-bold"
												color="cyan"
												block
												dark
												elevation="0"
												height="48"
											>
												Select Return
											</v-btn>
										</div>
									</v-col>
								</v-row>
							</v-card>
						</v-hover>
					</v-col>

					<v-col
						v-if="getSearchResultPlaneOneWay.trips"
						class="font-weight-bold text-h6"
						cols="12"
					>
						Returning flights
					</v-col>

					<v-col
						v-for="(item, i) in getSearchResultPlaneOneWay.trips"
						:key="'oneWay' + i"
						cols="12"
					>
						<v-hover v-slot="{ hover }">
							<v-card
								:elevation="hover ? 6 : 0"
								class="overflow-hidden pa-6 rounded-lg transition-swing"
								flat
								width="100%"
							>
								<v-row>
									<v-col class="d-flex flex-column flex-wrap" cols="12" md="8">
										<v-row>
											<v-col class="align-start d-flex" cols="12">
												<v-img
													:src="getCompanyLogo(item)"
													class="mt-1 mr-6 rounded"
													contain
													height="38px"
													max-width="100px"
													style="border: 2px solid #eee"
												/>

												<div class="d-flex flex-column">
													<span
														v-text="item.company.company"
														class="font-weight-bold text-body-1"
													/>

													<div class="font-weight-bold text-body-2">
														<span v-text="item.vehicle.detail.brand" />

														<span v-text="' - '" />

														<span v-text="item.vehicle.detail.model" />
													</div>
												</div>
											</v-col>

											<v-col class="trip__grid font-weight-bold text-body-2">
												<div class="trip__grid--where-from text-center">
													{{ item.oneWay.source.city }}
													({{ item.oneWay.source.code }})
												</div>

												<div class="trip__grid--time-from text-center">
													{{ getHour(item.oneWay.departureDate) }}
													-
													{{ getDateFormat(item.oneWay.departureDate) }}
												</div>

												<div
													v-if="item.scales === 0"
													class="trip__grid--type green--text text--lighten-1 text-center"
												>
													Nonstop
												</div>
												<div
													v-if="item.scales === 1"
													class="trip__grid--type green--text text--lighten-1 text-center"
												>
													1 Scale
												</div>
												<div
													v-if="item.scales > 1"
													class="trip__grid--type green--text text--lighten-1 text-center"
												>
													{{ item.scales }} Scales
												</div>

												<div class="trip__grid--where-to text-center">
													{{ item.oneWay.destination.city }}
													({{ item.oneWay.destination.code }})
												</div>

												<div class="trip__grid--time-to text-center">
													{{ getHour(item.oneWay.arrivalDate) }}
													-
													{{ getDateFormat(item.oneWay.arrivalDate) }}
												</div>
											</v-col>

											<v-col
												class="align-center d-flex flex-column flex-md-row justify-space-between font-weight-bold text-body-2"
												cols="12"
											>
												<div>
													Duration:
													{{ parseDuration(item.oneWay.flightTime) }}
												</div>

												<div class="cyan--text font-weight-bold text-body-1">
													{{ getSegment(item) }}
												</div>
											</v-col>

											<v-col class="font-weight-bold text-body-2" cols="12">
												<v-card
													class="d-flex align-center flex-row justify-space-between pa-3 rounded-lg"
													color="green lighten-5"
													flat
													width="100%"
												>
													<div class="mr-3 green--text text--darken-3">
														Greenhouse gas emissions for this flight are
														compensated by V1
													</div>

													<v-spacer />

													<v-img
														contain
														height="44px"
														max-width="44px"
														src="@/assets/badge__v1--green.png"
														style="border-radius: 50%"
														width="44px"
													/>

													<v-spacer />
												</v-card>
											</v-col>
										</v-row>
									</v-col>

									<v-col
										class="d-none d-md-flex justify-center my-n3 py-0"
										cols="1"
									>
										<v-divider vertical style="border-style: dashed" />
									</v-col>

									<v-col
										class="d-flex d-md-none mx-n3 px-0"
										cols="12"
										style="flex: auto; max-width: calc(100% + 24px)"
									>
										<v-divider style="border-style: dashed" />
									</v-col>

									<v-col
										class="d-flex flex-column justify-center justify-md-space-between text-center text-md-right"
										cols="12"
										md="3"
									>
										<div
											class="align-center d-flex justify-center justify-md-end font-weight-bold text-h6"
										>
											<v-icon color="cyan"> mdi-seat-passenger </v-icon>

											{{ item.currency }} {{ item.price }}
										</div>

										<div class="mt-1 text-body-1">Price complete plane</div>

										<div
											class="align-center d-flex font-weight-bold justify-center justify-md-end mt-1 mb-6 mb-md-auto cyan--text text-body-2"
										>
											<v-icon color="cyan" small> mdi-seat-passenger </v-icon>

											{{ item.paxAvailable }}
										</div>
										<div>
											<v-btn
												@click="saveReturnGDS(item)"
												class="font-weight-bold"
												color="cyan"
												block
												dark
												elevation="0"
												height="48"
											>
												Select Return
											</v-btn>
										</div>
									</v-col>
								</v-row>
							</v-card>
						</v-hover>
					</v-col>

					<v-col
						v-if="getSearchResultFlightsCommercials.trips"
						class="font-weight-bold text-h6"
						cols="12"
					>
						Returning commercial flights
					</v-col>

					<v-col
						v-for="(item, i) in getSearchResultFlightsCommercials.trips"
						:key="'commercials' + i"
						cols="12"
					>
						<v-hover v-slot="{ hover }">
							<v-card
								:elevation="hover ? 6 : 0"
								class="overflow-hidden pa-6 rounded-lg transition-swing"
								flat
								width="100%"
							>
								<v-row>
									<v-col class="d-flex flex-column flex-wrap" cols="12" md="8">
										<v-row>
											<v-col class="align-start d-flex" cols="12">
												<v-img
													:src="item.company.image"
													class="mt-1 mr-6 rounded"
													contain
													height="38px"
													max-width="100px"
													style="border: 2px solid #eee"
												/>

												<div class="d-flex flex-column">
													<span
														v-text="item.company.company"
														class="font-weight-bold text-body-1"
													/>

<!--													<div class="font-weight-bold text-body-2">-->
<!--														<span v-text="item.vehicle.detail.brand" />-->

<!--														<span v-text="' - '" />-->

<!--														<span v-text="item.vehicle.detail.model" />-->
<!--													</div>-->
												</div>
											</v-col>

											<v-col class="trip__grid font-weight-bold text-body-2">
												<div class="trip__grid--where-from text-center">
													{{ item.oneWay.source.city }}
													({{ item.oneWay.source.code }})
												</div>

												<div class="trip__grid--time-from text-center">
													{{ getHour(item.oneWay.departureDate) }}
													-
													{{ getDateFormat(item.oneWay.departureDate) }}
												</div>

												<div
													v-if="item.scales === 0"
													class="trip__grid--type green--text text--lighten-1 text-center"
												>
													Nonstop
												</div>
												<div
													v-if="item.scales === 1"
													class="trip__grid--type green--text text--lighten-1 text-center"
												>
													1 Scale
												</div>
												<div
													v-if="item.scales > 1"
													class="trip__grid--type green--text text--lighten-1 text-center"
												>
													{{ item.scales }} Scales
												</div>

												<div class="trip__grid--where-to text-center">
													{{ item.oneWay.destination.city }}
													({{ item.oneWay.destination.code }})
												</div>

												<div class="trip__grid--time-to text-center">
													{{ getHour(item.oneWay.arrivalDate) }}
													-
													{{ getDateFormat(item.oneWay.arrivalDate) }}
												</div>
											</v-col>

											<v-col
												class="align-center d-flex flex-column flex-md-row justify-space-between font-weight-bold text-body-2"
												cols="12"
											>
												<div>
													Duration:
													{{ parseDuration(item.oneWay.flightTime) }}
												</div>

												<div class="cyan--text font-weight-bold text-body-1">
													{{ getSegment(item) }}
												</div>
											</v-col>

											<v-col class="font-weight-bold text-body-2" cols="12">
												<v-card
													class="d-flex align-center flex-row justify-space-between pa-3 rounded-lg"
													color="green lighten-5"
													flat
													width="100%"
												>
													<div class="mr-3 green--text text--darken-3">
														Greenhouse gas emissions for this flight are
														compensated by V1
													</div>

													<v-spacer />

													<v-img
														contain
														height="44px"
														max-width="44px"
														src="@/assets/badge__v1--green.png"
														style="border-radius: 50%"
														width="44px"
													/>

													<v-spacer />
												</v-card>
											</v-col>
										</v-row>
									</v-col>

									<v-col
										class="d-none d-md-flex justify-center my-n3 py-0"
										cols="1"
									>
										<v-divider vertical style="border-style: dashed" />
									</v-col>

									<v-col
										class="d-flex d-md-none mx-n3 px-0"
										cols="12"
										style="flex: auto; max-width: calc(100% + 24px)"
									>
										<v-divider style="border-style: dashed" />
									</v-col>

									<v-col
										class="d-flex flex-column justify-center justify-md-space-between text-center text-md-right"
										cols="12"
										md="3"
									>
										<div
											class="align-center d-flex justify-center justify-md-end font-weight-bold text-h6"
										>
											<v-icon color="cyan"> mdi-seat-passenger </v-icon>

											{{ item.currency }} {{ item.price }}
										</div>

										<div class="mt-1 text-body-1">Price per seat</div>

										<div
											class="align-center d-flex font-weight-bold justify-center justify-md-end mt-1 mb-6 mb-md-auto cyan--text text-body-2"
										>
											<v-icon color="cyan" small> mdi-seat-passenger </v-icon>

											{{ item.paxAvailable }}
										</div>
										<div>
											<v-btn
												@click="saveReturnGDS(item)"
												class="font-weight-bold"
												color="cyan"
												block
												dark
												elevation="0"
												height="48"
											>
												Select Return
											</v-btn>
										</div>
									</v-col>
								</v-row>
							</v-card>
						</v-hover>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Aside from '@/components/search/Aside';
import FlightDetails from '@/components/search/FlightDetails';
import FlightFeatures from '@/components/search/FlightFeatures';
import FlightDetailsGDS from '@/components/search/FlightDetailsGDS';
import { basePathApi } from '@/services/axiosURI.service';

export default {
	components: {
		Aside,
		FlightDetails,
		FlightDetailsGDS,
		FlightFeatures,
	},
	methods: {
		...mapActions([
			'loadSearchResultInverted',
			'flightToAction',
			'actionFromType',
			'actionToType',
		]),
		getHour(date) {
			return this.dayjs(date).utc().format('HH:mm');
		},
		getDateFormat(date) {
			return this.dayjs(date).utc().format('MM/DD/YY');
		},
		parseDuration(duration) {
			let timeTotal = this.dayjs.duration(duration, 'm');
			return `${timeTotal.hours()} hs ${timeTotal.minutes()} min`;
		},
		saveReturn(item) {
			this.flightToAction(item);
			this.actionToType('Offer');
			if (this.$route.path !== '/search/details') {
                this.$router.push('/search/details');
            }
		},
		saveReturnGDS(item) {
			this.flightToAction(item);
			this.actionToType('GDS');
			if (this.$route.path !== '/search/details') {
                this.$router.push('/search/details');
            }
		},
		getCompanyLogo(item) {
			if (item.company?.id ?? null) {
				return basePathApi + item.company.image;
			}
			return '';
		},
		getCompanyName(item) {
			return item.company?.company;
		},
		getBrand(item) {
			return item.vehicle?.detail?.brand;
		},
		getModel(item) {
			return item.vehicle?.detail?.model;
		},
		getSegment(item) {
			return item.vehicle?.detail?.segment;
		},
		getPaxAvailable(item) {
			return item.paxAvailable;
		},
		getPax(item) {
			return item.pax;
		},
		editDeparture() {
			this.$router.push('/search/depart');
		},
	},
	computed: {
		...mapGetters([
			'getSearchResultSeats',
			'getSearchResultPlaneOneWay',
			'getSearchResultFlightsCommercials',
			'getSearchFrom',
			'getSearchTo',
			'getFlightFrom',
			'getFromType',
			'getToType',
		]),
	},
	async created() {
		if (!this.getSearchFrom) {
			this.$router.push('/flights');
		}
		await this.loadSearchResultInverted();
	},
};
</script>

<style lang="scss" scoped>
.card__previous {
	backdrop-filter: blur(4px);
	border-radius: 8px;
	box-shadow: none !important;
	margin-top: 0 !important;
	pointer-events: none;
}
</style>
