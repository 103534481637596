import axios from 'axios';
import {
	AirportsApi,
	AuthApi,
	OffersApi,
	ImagesApi,
	UsersApi,
	GDSApi,
	FlightsCommercialsApi,
	PaymentsApi,
} from 'jetsbooking_node_api_sdk';

const jetsbookingSdkAxios = axios.create({
	timeout: 10000,
});
const basePathApi = process.env.VUE_APP_API_HOST_V2;
const authApi = new AuthApi(null, basePathApi, jetsbookingSdkAxios);
const airportsApi = new AirportsApi(null, basePathApi, jetsbookingSdkAxios);
const flightsApi = new OffersApi(null, basePathApi, jetsbookingSdkAxios);
const imagesApi = new ImagesApi(null, basePathApi, jetsbookingSdkAxios);
const usersApi = new UsersApi(null, basePathApi, jetsbookingSdkAxios);
const flightGds = new GDSApi(null, basePathApi, jetsbookingSdkAxios);
const flightsCommercialsApi = new FlightsCommercialsApi(null, basePathApi, jetsbookingSdkAxios);
const paymentsApi = new PaymentsApi(null, basePathApi, jetsbookingSdkAxios);

const mainAxios = axios.create({
	baseURL: basePathApi + '/api/',
	timeout: 10000,
});

setInterceptors(jetsbookingSdkAxios);
setInterceptors(mainAxios);

function setInterceptors(instance) {
	instance.interceptors.request.use((request) => {
		const user = JSON.parse(sessionStorage.getItem('user'));
		const headers = {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			Accept: 'application/json',
		};
		request.headers = headers;
		if (user) {
			request.headers = headers;
			request.headers['Authorization'] = `Bearer ${user.accessToken}`;
		}

		// Request started
		// if (process.env.NODE_ENV === 'development') {
		// 	console.log(
		// 		`*******[API] Started ${request.method} ${request.url}*******`
		// 	);
		// }
		return request;
	});

	instance.interceptors.response.use(
		(response) => {
			// Success response
			// if (process.env.NODE_ENV === 'development') {
			// 	console.info({
			// 		status: response.status,
			// 		URL: response.request.responseURL,
			// 		data: response.data,
			// 	});
			// }
			return response;
		},
		(error) => {
			console.log(error);
			if (error.response.status === 401 || error.response.status === 500) {
				// store.dispatch('logout');
			}

			// Error response
			// if (process.env.NODE_ENV === 'development') {
			// 	console.error({
			// 		status: error.status,
			// 		URL: error.request.responseURL,
			// 		data: error.data,
			// 	});
			// }
			return error;
		}
	);
}

export {
	basePathApi,
	authApi,
	airportsApi,
	flightsApi,
	flightGds,
	flightsCommercialsApi,
	imagesApi,
	usersApi,
	paymentsApi,
	mainAxios,
};
